import React from 'react';
import { Link } from 'react-router-dom';

function Navbar2({ onLanguageChange }) {
    return (
        <nav className="navbar2">
            <div className="navbar-container">

                <div className="button-container">
                    <Link to="/english" onClick={() => onLanguageChange('en')}>
                        <button className="btn">English Movies</button>
                    </Link>
                    <Link to="/hindi" onClick={() => onLanguageChange('hi')}>
                        <button className="btn">Hindi Movies</button>
                    </Link>
                    <Link to="/kannada" onClick={() => onLanguageChange('kn')}>
                        <button className="btn">Kannada Movies</button>
                    </Link>
                    <Link to="/gujarati" onClick={() => onLanguageChange('gu')}>
                        <button className="btn">Gujarati Movies</button>
                    </Link>
                    <Link to="/tamil" onClick={() => onLanguageChange('ta')}>
                        <button className="btn">Tamil Movies</button>
                    </Link>
                    <Link to="/telugu" onClick={() => onLanguageChange('te')}>
                        <button className="btn">Telugu Movies</button>
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar2;
