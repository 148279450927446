import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "/React JS/Bolly4u/src/Assets/katmoviehd.png";

function Navbar({ onGenreChange }) {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <h1 className="navbar-title">
                    <Link to="/"><img src={logo} alt="Logo" style={{ height: '30px' }} /></Link>
                </h1>

                {/* Hamburger button for mobile */}
                <div className="hamburger" onClick={toggleMobileMenu}>
                    ☰
                </div>

                <div className={`navbar-buttons ${isMobileMenuOpen ? 'open' : ''}`}>
                    <Link to="/genre/action" className="navbar-button" onClick={toggleMobileMenu}>Action</Link>
                    <Link to="/genre/comedy" className="navbar-button" onClick={toggleMobileMenu}>Comedy</Link>
                    <Link to="/genre/drama" className="navbar-button" onClick={toggleMobileMenu}>Drama</Link>
                    <Link to="/genre/horror" className="navbar-button" onClick={toggleMobileMenu}>Horror</Link>
                    <Link to="/genre/romance" className="navbar-button" onClick={toggleMobileMenu}>Romance</Link>
                    <Link to="/genre/sci-fi" className="navbar-button" onClick={toggleMobileMenu}>Sci-Fi</Link>
                    <Link to="/genre/animation" className="navbar-button" onClick={toggleMobileMenu}>Animation</Link>
                    <Link to="/genre/documentary" className="navbar-button" onClick={toggleMobileMenu}>Documentary</Link>
                    <Link to="/genre/thriller" className="navbar-button" onClick={toggleMobileMenu}>Thriller</Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
