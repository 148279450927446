import React, { useEffect, useState } from "react";

const DownloadButton = () => {
    const [showDownload, setShowDownload] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(60); 

    useEffect(() => {
        if (secondsLeft > 0) {
            const timer = setInterval(() => {
                setSecondsLeft((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(timer); // Cleanup timer on component unmount
        } else {
            setShowDownload(true);
        }
    }, [secondsLeft]);

    const handleDownloadClick = () => {
        window.location.href = "https://katmoviehd.fi/tag/hd/";
    };

    return (
        <div style={{ textAlign: "center" }}>
            {showDownload ? (
                <button className="download-button" onClick={handleDownloadClick}>Download</button>
            ) : (
                <strong className="download-para">Download button will appear in {secondsLeft} seconds...</strong> // Message with countdown
            )}
        </div>
    );
};

export default DownloadButton;
