import React from "react";
import { useRef, useEffect } from "react";

function Contatctus() {

    const scrollPosition = useRef(0);

    useEffect(() => {
        window.scrollTo(0, scrollPosition.current);
        return () => {
            scrollPosition.current = window.scrollY;
        };
    });
    return (
        <div className="movie-details">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfcl1lWp0u00JwHj0JJ0XWzCguzdPBNV8vmwj1dQJsuBRvGxA/viewform?embedded=true" width="640" height="686" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    );
}

export default Contatctus;
