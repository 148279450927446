import React from "react";
import { useRef, useEffect } from "react";

function Aboutus() {
    const scrollPosition = useRef(0);

    useEffect(() => {
        window.scrollTo(0, scrollPosition.current);
        return () => {
            scrollPosition.current = window.scrollY;
        };
    });
    return (
        <div className="movie-details">
            <div className="budget-revenue">
                <h2>About Us Katmoviehd</h2>
                <p> KatmovieHD was born out of a passion for cinema and a desire to share that passion with others. Our journey began with a simple goal: to create a one-stop destination for movie enthusiasts to discover and explore the world of films. Today, our website, katmoviehd.buzz, is the go-to platform for millions of users seeking in-depth information on the latest movies and timeless classics.</p>

                <h3>Mission Statement</h3>
                <p>Our mission is to ignite a sense of wonder and excitement in every movie lover's heart. We strive to provide accurate, comprehensive, and engaging content that helps our users make informed decisions about what to watch next. By doing so, we aim to foster a community that shares our passion for cinema and inspires others to do the same.</p>

                <h3>Core Values</h3>
                <p>Passion for Cinema: We live and breathe movies, and our content reflects our genuine enthusiasm for the art form.
                    Accuracy and Reliability: We're committed to providing the most accurate and trustworthy information to our users.
                    Diversity and Inclusion: We celebrate the diversity of global cinema, honoring the uniqueness of different genres, languages, and cultures.
                    Community Building: We believe in the power of shared experiences and strive to create a welcoming environment for movie enthusiasts to connect and share their love for films.</p>
                <h3>Team Highlights</h3>
                <p>Our team of dedicated movie buffs works tirelessly to bring you the best content, from in-depth reviews to behind-the-scenes stories. With a collective passion for cinema, we strive to create a community that's informative, engaging, and fun.</p>
                <h3>Join the Journey</h3>
                <p>Be a part of the KatmovieHD community today! Explore our website, engage with our content, and let's discover the magic of movies together.</p>
            </div>
        </div>
    );
}

export default Aboutus;
