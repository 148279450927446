import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MovieCard from './MoviesCard';

const API_KEY = 'f2d9abbddac1684b671d7d149d24779b';
const BASE_API_URL = 'https://api.themoviedb.org/3/discover/movie';

// Mapping genre IDs to names
const genreMap = {
    28: 'Action',
    35: 'Comedy',
    18: 'Drama',
    27: 'Horror',
    10749: 'Romance',
    878: 'Sci-Fi',
    16: 'Animation',
    99: 'Documentary',
    53: 'Thriller',
};

// Reverse mapping from genre names to IDs
const genreNameToId = Object.fromEntries(Object.entries(genreMap).map(([id, name]) => [name.toLowerCase(), id]));

const GenreMovies = () => {
    const { genreName } = useParams();
    const genreId = genreNameToId[genreName.toLowerCase()]; // Lookup genre ID from name
    const [movies, setMovies] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const fetchMoviesByGenre = async () => {
        if (!genreId) {
            console.error("Invalid genre name in URL.");
            return;
        }
        try {
            const response = await fetch(`${BASE_API_URL}?api_key=${API_KEY}&with_genres=${genreId}&page=${page}`);
            const data = await response.json();
            console.log("API Response:", data); // Debug log
            setMovies(data.results);
            setTotalPages(data.total_pages);
        } catch (error) {
            console.error("Error fetching movies:", error);
        }
    };

    useEffect(() => {
        console.log("Fetching movies for genre ID:", genreId); // Debug log
        fetchMoviesByGenre();
        window.scrollTo(0, 0);
    }, [genreId, page]);

    const nextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const prevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    return (
        <div>
            <div className="container">
                <h2>Genre: {genreMap[genreId] || 'Unknown Genre'}</h2>
                {movies?.length > 0 ? (
                    movies.map((movie) => <MovieCard key={movie.id} movie={movie} />)
                ) : (
                    <div className="empty">
                        <h2>No Movies Found</h2>
                    </div>
                )}
                <div className="pagination">
                    <button disabled={page === 1} onClick={prevPage}>Previous</button>
                    <span>Page {page} of {totalPages}</span>
                    <button disabled={page === totalPages} onClick={nextPage}>Next</button>
                </div>
            </div>
        </div>
    );
};

export default GenreMovies;
