import React, { useEffect, useState } from "react";
import MovieCard from "./MoviesCard";
import { Helmet } from "react-helmet"; // To manage the preload of images

const LanguageMovies = ({ language, fetchMoviesByLanguage }) => {
    const [movies, setMovies] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    // Load movies based on the selected language and current page
    const loadMovies = async () => {
        setLoading(true);
        const cachedMovies = JSON.parse(localStorage.getItem(`movies-${language}-${page}`));

        if (cachedMovies) {
            setMovies(cachedMovies.results);
            setTotalPages(cachedMovies.total_pages);
            setLoading(false);
        } else {
            try {
                const response = await fetchMoviesByLanguage(language, page);
                setMovies(response.results || []);
                setTotalPages(response.total_pages || 0);
                // Cache the movies in localStorage for future use
                localStorage.setItem(`movies-${language}-${page}`, JSON.stringify(response));
            } catch (error) {
                console.error("Error fetching movies:", error);
                setMovies([]); // Clear movies on error
                setTotalPages(0);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        loadMovies();
    }, [language, page]);

    const nextPage = () => {
        if (page < totalPages) {
            setPage((prevPage) => prevPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
            window.scrollTo(0, 0);
        }
    };

    // Map language codes to display names
    const languageMap = {
        en: "English",
        hi: "Hindi",
        kn: "Kannada",
        gu: "Gujarati",
        ta: "Tamil",
        te: "Telugu",
    };

    const genreTitle = languageMap[language.toLowerCase()] || "Movies";

    if (loading) {
        return <h2>Loading...</h2>;
    }

    return (
        <>
            <Helmet>
                {/* Preload the main poster image for faster load */}
                {movies.slice(0, 5).map((movie) => (
                    <link
                        key={movie.id}
                        rel="preload"
                        href={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        as="image"
                    />
                ))}
            </Helmet>

            <div className="container">
                <h2>{genreTitle} Movies</h2>
                {movies?.length > 0 ? (
                    movies.map((movie) => <MovieCard key={movie.id} movie={movie} />)
                ) : (
                    <div className="empty">
                        <h2>No Movies Found</h2>
                    </div>
                )}
                {/* Pagination controls */}
                <br />
                <div className="pagination">
                    <button className="prev-btn" disabled={page === 1} onClick={prevPage}>
                        Previous
                    </button>
                    <span className="page-info">
                        Page {page} of {totalPages}
                    </span>
                    <button className="next-btn" disabled={page === totalPages} onClick={nextPage}>
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default LanguageMovies;
