import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const date = new Date().getFullYear();

function Footer() {
    const scrollPosition = useRef(0); // Ref to store scroll position

    useEffect(() => {
        window.scrollTo(0, scrollPosition.current); // Scroll to saved position

        return () => {
            scrollPosition.current = window.scrollY; // Save current scroll position on unmount
        };
    }, []); // Runs once when the footer mounts

    return (
        <div className="movie-details">
            <div className="budget-revenue">
                <h3>KatMovieHD 2024</h3>
                <p >
                    <strong>KatMovieHD</strong> is an entertainment portal that gives updates on the latest Bollywood, South, Tamil, Telugu, Malayalam, Kannada, Pubjabi movies and Web Series. In 2024, the market offers many options with quality content, but katmovie hd has a different name because of a diverse range of options for entertainment.
                </p>
                <p>
                    If you want to see every new and old release then you can visit the site containing many titles that include reviews of Hindi as well as Dubbed version of Hollywood Movies. We provide information about box office details as well as updates and reviews at the earliest. Various genres such as Drama, Crime, Action, Comedy, Horror, Thriller, Adventure, etc.
                </p>
                <p>
                    <strong>Katmoviehd.buzz</strong> is its new domain, with updated content and a new content addition every day. We ensure that we give you a full review and the latest news concerning new movies so you are always up to date.
                </p>
                <p>
                    Our content is fair and covers both the good and bad parts of each movie, which will help you in deciding on what to watch. <strong>Katmoviehd</strong> brings you content from various film industries. It matters whether you love Bollywood, South Indian thrillers, or dubbed Hollywood movies; we have it all. The genres categories assist you in finding movies that match your mood, whether you want to laugh, get scared, or feel excited.
                </p>

                <hr style={{ border: "2px solid #FFD700 " , marginBottom: "10px", marginTop:"10px"}}/>

                <h3>Katmoviehd Disclaimer</h3>
                <p>
                    The information provided on this website is for general informational purposes only, and while we strive to ensure its accuracy, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained herein. Our movie recommendations and reviews are subjective opinions, and users should conduct their own research and form their own judgments before making decisions based on the information provided. We are not responsible for the content or privacy practices of external websites linked to this site, and all movie-related content is the property of their respective owners and is used here for informational purposes. We reserve the right to amend this disclaimer at any time, and by using this website, you agree to be bound by the current version of these terms and conditions.
                </p>
                <h4>All Images Credit - <a href="https://www.themoviedb.org/">TMDB</a></h4>

                <hr style={{ border: "2px solid #FFD700 " , marginBottom: "10px", marginTop:"10px"}}/>

                <h3>Search Keywords</h3>
                <p>katmoviehd,
                    catmoviehd,
                    cat movie hd,
                    cat movie hd in,
                    cat movie hd com,
                    catmoviehd com,
                    katmoviehd action,
                    katmoviehd telegram,
                    katmoviehd south movie,
                    katmoviehd app,
                    katmoviehd apk,
                    katmoviehd comedy,
                    katmoviehd Netflix,
                    horror katmoviehd,
                    katmoviehd day,
                    katmoviehd old,
                    katmoviehd com 2024,
                    katmoviehd kannada,
                    katmoviehd new domain 2024,
                    katmoviehd id,
                    katmoviehd dv,
                    katmoviehd archieve,
                    katmoviehd foo,
                    katmoviehd official website,
                    katmoviehd alternative,
                    katmoviehd amazon prime,
                    catmoviehd.com 2024 bollywood,
                    katmoviehd com 2024 bollywood movie,
                    catmoviehd.com bolllywood,
                    catmoviehd.com 2021,
                    catmoviehd.com 2022,
                    catmoviehd com hollywood,
                    catmoviehd.com 2023 Bollywood,
                    www katmoviehd com,
                    www katmovie,
                    katmoviehd to,
                    katmoviehd south movies,
                    katmoviehd south movie hindi,
                    katmoviehd re,
                    katmoviehd official site,
                    katmoviehd nz,
                    katmoviehd new,
                    katmoviehd netflix movies,
                    katmoviehd movie,
                    katmoviehd game of thrones,
                    katmoviehd com 2021 bollywood,
                    katmoviehd com 2021,
                    katmoviehd bollywood movies 2022,
                    katmoviehd animated movies,
                    katmoviehd action movies,
                    katmoviehd 2022,
                    katmovie tv,
                    katmovie online,
                    katmovie movies,
                    katmovie hindi movies,
                    katmovie hd 2022,
                    katmovie download,
                    kat movie hd download,
                    kat movie download,
                    www katmoviehd,
                    ketmoviehd tv com,
                    katmoviehd official website,
                    katmoviehd animated,
                    katmovie web,
                    katmovie in,
                    katmovie com,
                    katmoviehd netflix,
                    katmovie hd in,
                    katmovie 18,
                    kat movie hd com,
                    katmovie,
                    katmovieshd com
                </p>

                <p >
                    <Link to="/aboutus">About Us</Link> | <Link to="/contactus">Contact Us</Link> | <Link to="/disclaimer">Disclaimer</Link> | <Link to="/privacy">Privacy Policy</Link> | <Link to="/terms">Terms and Conditions</Link>
                    <p>&copy;{date} Katmoviehd</p>
                </p>

            </div>
        
        </div>
    );
}

export default Footer;
