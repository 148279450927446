import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import SearchIcon from "./search.svg";
import MovieDetails from "./Components/MovieDetails";
import Aboutus from "./Components/About";
import Contactus from "./Components/Contactus";
import Disclaimer from "./Components/Disclaimer";
import Privacy from "./Components/Privacy";
import Terms from "./Components/Terms";
import Navbar from "./Components/Navbar";
import Navbar2 from "./Components/Navbar2";
import Footer from "./Components/Footer";
import GenreMovies from "./Components/GenreMovies";
import LanguageMovies from "./Components/Languagesection";
import Home from "./Components/Home";

// Function to fetch movies by language (passed as a prop)
const fetchMoviesByLanguage = async (language, page = 1) => {
    const API_URL = `https://api.themoviedb.org/3/discover/movie`;
    const API_KEY = "f2d9abbddac1684b671d7d149d24779b";

    try {
        const response = await fetch(
            `${API_URL}?api_key=${API_KEY}&with_original_language=${language}&page=${page}`
        );
        if (!response.ok) {
            throw new Error(`Error fetching movies: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch movies:", error.message);
        return { results: [], total_pages: 0 }; // Return a fallback response
    }
};

function App() {
    const [language, setLanguage] = useState("en"); // Default language is English

    // Function to handle language changes
    const onLanguageChange = (newLanguage) => {
        setLanguage(newLanguage); // Update the language when user selects a different option
    };

    return (
        <div className="app">
            <Navbar />
            <Navbar2 onLanguageChange={onLanguageChange} /> {/* Pass onLanguageChange to Navbar2 */}

            <Routes>
                {/* Home Route */}
                <Route path="/" element={<Home searchIcon={SearchIcon} />} />

                {/* Genre Movies Route */}
                <Route path="/genre/:genreName" element={<GenreMovies />} />

                {/* Language Movies Route, with fetchMoviesByLanguage passed as a prop */}
                <Route
                    path="/:language"
                    element={
                        <LanguageMovies
                            language={language}
                            fetchMoviesByLanguage={fetchMoviesByLanguage} // Pass fetchMoviesByLanguage prop
                        />
                    }
                />

                {/* Movie Details Route */}
                <Route path="/movie/:slug" element={<MovieDetails />} />

                {/* Additional Routes */}
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/contactus" element={<Contactus />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>

            {/* Footer */}
            <Footer />
        </div>
    );
}

// Wrapper component to include the Router
export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}
