import React from "react";
import { useRef,useEffect } from "react";
import { Link } from "react-router-dom";

function Disclaimer() {


    const scrollPosition = useRef(0);

    useEffect(() => {
        window.scrollTo(0, scrollPosition.current);
        return () => {
            scrollPosition.current = window.scrollY;
        };
    });

    return (
        <div className="movie-details">
        <div className="budget-revenue">
            <h2>Disclaimer</h2>
            <p>KatmovieHD is an informational platform that provides detailed insights, reviews, and ratings for movies from various genres and languages. We use the TMDB (The Movie Database) API to fetch and display movie data, including overviews, IMDb ratings, cast information, and other relevant details.</p>

<h3>Please Note:</h3>

<p>KatmovieHD is not affiliated with TMDB or IMDb, nor does it claim ownership of the movie data and media content displayed on this website. All rights to movie details, images, and metadata belong to their respective copyright holders.</p>
<p>KatmovieHD does not host or stream movies or TV shows. We do not store any content on our servers; all information is sourced purely for informational and educational purposes.</p>
<p>If you have any copyright concerns or inquiries, please reach out to us via our <Link to="/contactus">Contact Us</Link> page.</p>

        </div>
        </div>
    );
}

export default Disclaimer;
