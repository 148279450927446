import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import DownloadButton from "./Download";
import MovieCard from "./MoviesCard";

const API_KEY = 'f2d9abbddac1684b671d7d149d24779b';

function MovieDetails() {
    const [movie, setMovie] = useState(null);
    const [loading, setLoading] = useState(true);
    const scrollPosition = useRef(0);
    const [videos, setVideos] = useState([]);
    const location = useLocation();
    const [relatedMovies, setRelatedMovies] = useState([]);
    const movieGenres = movie?.genres.map((genre) => genre.id) || [];
    const language = location.state?.original_language;

    const languageMap = {
        hi: 'Hindi',
        bn: 'Bengali',
        te: 'Telugu',
        mr: 'Marathi',
        ta: 'Tamil',
        gu: 'Gujarati',
        kn: 'Kannada',
        ml: 'Malayalam',
        or: 'Odia',
        pa: 'Punjabi',
        as: 'Assamese',
        bh: 'Bhojpuri',
        ur: 'Urdu',
        ks: 'Kashmiri',
        sd: 'Sindhi',
        sa: 'Sanskrit',
        mni: 'Manipuri',
        kok: 'Konkani',
        doi: 'Dogri',
        en: 'English',
        es: 'Spanish',
        fr: 'French',
        de: 'German',
        ja: 'Japanese',
    };

    const fetchRelatedMovies = async (id) => {
        try {
            const response = await fetch(`https://api.themoviedb.org/3/movie/${id}/similar?api_key=${API_KEY}&page=1`);
            if (!response.ok) throw new Error("Failed to fetch related movies");

            const data = await response.json();
            const firstGenre = movieGenres[0];

            const filteredRelatedMovies = data.results.filter((movie) => {
                const hasMatchingGenre = movie.genre_ids[0] === firstGenre;
                return hasMatchingGenre;
            });

            setRelatedMovies(filteredRelatedMovies || []);
        } catch (error) {
            console.error("Error fetching related movies:", error);
        }
    };

    const fetchGenreMovies = async () => {
        try {
            const genreId = movieGenres[0];
            const response = await fetch(`https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&with_genres=${genreId}&with_original_language=${language}&page=1`);

            if (!response.ok) throw new Error("Failed to fetch genre-based movies");

            const data = await response.json();
            const filteredMovies = data.results.filter((relatedMovie) => relatedMovie.id !== location.state?.id);

            setRelatedMovies(filteredMovies);
        } catch (error) {
            console.error("Error fetching genre-based movies:", error);
        }
    };

    useEffect(() => {
        if (movieGenres.length > 0) {
            fetchGenreMovies();
        }
    }, [movieGenres]);

    const fetchMovieById = async (id) => {
        try {
            const response = await fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=en-US&append_to_response=credits,videos,reviews,images`);
            if (!response.ok) throw new Error("Failed to fetch movie by ID");

            const data = await response.json();
            setVideos(data.videos?.results || []);
            return data;
        } catch (error) {
            console.error("Error fetching movie by ID:", error);
            return null;
        }
    };

    const fetchMovieDetails = async () => {
        setLoading(true);
        const movieId = location.state?.id;
        if (movieId) {
            const detailsResponse = await fetchMovieById(movieId);
            if (detailsResponse) {
                setMovie(detailsResponse);
                await fetchRelatedMovies(movieId);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchMovieDetails();
    }, [location.state]);

    useEffect(() => {
        window.scrollTo(0, scrollPosition.current);
        return () => {
            scrollPosition.current = window.scrollY;
        };
    }, [movie]);

    const getCrewRole = (role) => {
        return movie?.credits?.crew?.find((member) => member.job === role)?.name || "Not available";
    };

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating / 2);
        const halfStars = rating % 2 >= 1 ? 1 : 0;
        const emptyStars = 5 - fullStars - halfStars;

        return (
            <>
                {"★".repeat(fullStars)}
                {halfStars ? "★" : ""}
                {"☆".repeat(emptyStars)}
            </>
        );
    };

    if (loading) return <h2>Loading...</h2>;
    if (!movie) return <h2>Movie not found.</h2>;

    return (
        <div className="movie-details">
            {movie.poster_path && (
                <picture>
                    <link rel="preload" href={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} as="image" />
                    <img
                        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        alt={movie.title}
                        className="movie-poster"
                        loading="lazy"
                    />
                </picture>
            )}
            <h3 className="movie-header">
                <Link to="/home" className="breadcrumb-link">Katmoviehd</Link>
                <span className="arrow"> &gt;&gt; </span>
                {movie.genres && movie.genres.length > 0 ? (
                    <Link to={`/genre/${movie.genres[0].name.toLowerCase()}`} className="breadcrumb-link">
                        {movie.genres[0].name}
                    </Link>
                ) : "Not available"}
                <span className="arrow"> &gt;&gt; </span> {movie.title} Movie Katmoviehd
            </h3>

            <h2>{movie.title} Movie Katmoviehd</h2>

            <table className="movie-details-table">
                <tbody>
                    <tr><th>Movie Name</th><td>{movie.title}</td></tr>
                    <tr>
                        <th>Original Language</th>
                        <td>{languageMap[movie.original_language] || movie.original_language}</td>
                    </tr>
                    <tr><th>Spoken Language</th>
                        <td>{movie.spoken_languages.length > 0 ? movie.spoken_languages.map(lang => lang.name).join(', ') : "Not available"}</td>
                    </tr>
                    <tr><th>Genres</th>
                        <td>{movie.genres.length > 0 ? movie.genres.map(genre => genre.name).join(', ') : "Not available"}</td>
                    </tr>
                    <tr><th>Production Companies</th>
                        <td>{movie.production_companies.length > 0 ? movie.production_companies.map(company => company.name).join(', ') : "Not available"}</td>
                    </tr>
                    <tr><th>Release Date</th>
                        <td>{new Date(movie.release_date).toLocaleDateString() || "Not available"}</td>
                    </tr>
                    <tr><th>Overview</th>
                        <td>{movie.overview || "Overview is not available."}</td>
                    </tr>
                    <tr><th>Director</th><td>{getCrewRole("Director")}</td></tr>
                    <tr><th>Writer</th><td>{getCrewRole("Writer")}</td></tr>
                    <tr><th>Producer</th><td>{getCrewRole("Producer")}</td></tr>
                    <tr><th>Screenplay</th><td>{getCrewRole("Screenplay")}</td></tr>
                    <tr><th>Runtime</th><td>{movie.runtime} minutes</td></tr>
                </tbody>
            </table>

            <div className="budget-revenue">
                <h3>{movie.title} Budget and Revenue Katmoviehd {movie.genres[0].name}</h3>
                <p>With a budget of <strong>Budget:</strong> ${movie.budget?.toLocaleString() || "N/A"}, the movie earned an unexpected amount of <strong>Revenue:</strong> ${movie.revenue?.toLocaleString() || "N/A"}. Whether it was a big hit or a flop, its box office results were surprising!</p>
            </div>

            <div className="budget-revenue">
                <h3>{movie.title} Cast Katmoviehd {movie.genres[0].name}</h3>
                <div className="cast-list">
                    {movie.credits.cast.slice(0, 10).map((actor) => (
                        actor.profile_path && (
                            <div key={actor.id} className="cast-member">
                                <img
                                    src={`https://image.tmdb.org/t/p/w185${actor.profile_path}`}
                                    alt={actor.name}
                                    className="actor-image"
                                    loading="lazy"
                                />
                                <div className="cast-info">
                                    <p className="cast-name">{actor.name}</p>
                                    <p className="cast-character">{actor.character}</p>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>

            <div className="budget-revenue">
                <h3>{movie.title} Trailer Katmoviehd {movie.genres[0].name}</h3>
                {videos.length > 0 ? (
                    videos.map((video) => (
                        <div key={video.id} className="videos">
                            <iframe
                                width="100%"
                                height="315"
                                src={`https://www.youtube.com/embed/${video.key}`}
                                title={video.name}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))
                ) : (
                    <p>No videos available.</p>
                )}
            </div>
            <div className="budget-revenue">
                <h3 className="related-header">Related Movies Katmoviehd {movie.genres[0].name}</h3>
                <div className="related-movies-list">
                    {relatedMovies.length > 0 ? (
                        relatedMovies.slice(0, 6).map((relatedMovie) => (
                            <MovieCard key={relatedMovie.id} movie={relatedMovie} />
                        ))
                    ) : (
                        <p>No related movies available.</p>
                    )}
                </div>

            </div>
            <br />

            <DownloadButton movie={movie} />
            <div className="faqs">
                <h3>Frequently Asked Questions</h3>
                <div className="faq-item">
                    <h4>What is the movie about?</h4>
                    <p>{movie?.overview || "Overview is not available."}</p>
                </div>
                <div className="faq-item">
                    <h4>Where can I watch the movie?</h4>
                    <p>Check streaming services like Netflix, Amazon Prime, Disney+, or other local services for availability.</p>
                </div>
                <div className="faq-item">
                    <h4>What is the movie's rating?</h4>
                    <p>{movie?.vote_average ? `${movie.vote_average}/10` : "Rating not available"}</p>
                </div>
                <div className="faq-item">
                    <h4>Who are the main actors in the movie?</h4>
                    <p>{movie?.credits?.cast?.slice(0, 3).map((actor) => actor.name).join(", ") || "Cast information is not available"}</p>
                </div>
                <div className="faq-item">
                    <h4>Is there a trailer for the movie?</h4>
                    <p>{videos.length > 0 ? "Yes, you can watch the trailer below." : "No trailer available."}</p>
                </div>
            </div>

        </div>
    );
}

export default MovieDetails;
