import React from "react";
import { useNavigate } from "react-router-dom";

function MovieCard({ movie, className }) {
    const navigate = useNavigate();
    const string = "katmoviehd";

    const languageMap = {
        hi: 'Hindi',
        bn: 'Bengali',
        te: 'Telugu',
        mr: 'Marathi',
        ta: 'Tamil',
        gu: 'Gujarati',
        kn: 'Kannada',
        ml: 'Malayalam',
        or: 'Odia',
        pa: 'Punjabi',
        as: 'Assamese',
        bh: 'Bhojpuri',
        ur: 'Urdu',
        ks: 'Kashmiri',
        sd: 'Sindhi',
        sa: 'Sanskrit',
        mni: 'Manipuri',
        kok: 'Konkani',
        doi: 'Dogri',
        en: 'English',
        es: 'Spanish',
        fr: 'French',
        de: 'German',
        ja: 'Japanese',
    };

    // Updated slugify function to ensure movie title is formatted correctly
    const slugify = (title, string) => {
        // Remove any invalid characters and replace spaces with hyphens
        const formattedTitle = title
            .toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "") // Remove special characters
            .trim()
            .replace(/\s+/g, "-"); // Replace spaces with hyphens
        return `${formattedTitle}-${string}`; // Append movie ID to the slug
    };

    const showMovieDetails = () => {
        const movieSlug = slugify(movie.title, string); // Generate slug with title and ID
        navigate(`/movie/${movieSlug}`, { state: { id: movie.id, genres: movie.genres, original_language: movie.original_language } });
    };

    return (
        <div className="movie" onClick={showMovieDetails}>
            <div>
                <p>{movie.release_date}</p>
            </div>
            <div className={`movie-card ${className}`}>
                {/* Using <picture> element for responsive images */}
                <picture>
                    <source
                        media="(max-width: 768px)"
                        srcSet={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                    />
                    <source
                        media="(min-width: 769px)"
                        srcSet={`https://image.tmdb.org/t/p/w780${movie.poster_path}`}
                    />
                    <img
                        src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} // Fallback image for non-responsive sizes
                        alt={movie.original_title}
                        loading="lazy" // Lazy load the image
                    />
                </picture>
            </div>
            <div>
                <span>{languageMap[movie.original_language] || movie.original_language}</span>
                <h3>{movie.title}</h3>
            </div>
        </div>
    );
}

export default MovieCard;
